<template>
  <!-- v-if="pages > limit" -->
  <CPagination align="center" aria-label="Page navigation">
    <CPaginationItem
      aria-label="Previous"
      component="button"
      :disabled="activePage <= 1"
      @click="pagingFirst"
      ><span aria-hidden="true">«</span></CPaginationItem
    >
    <CPaginationItem
      aria-label="Previous"
      component="button"
      :disabled="activePage <= 1"
      @click="pagingPrev"
      ><span aria-hidden="true">‹</span></CPaginationItem
    >
    <template v-for="(item, i) of pagingNumLast" :key="`paging_${i}`">
      <CPaginationItem
        component="button"
        v-if="pagingNumFirst <= item"
        @click="pageClick(item)"
        :active="activePage == item.toString()"
      >
        {{ item.toString() }}
      </CPaginationItem>
    </template>
    <CPaginationItem
      aria-label="Next"
      component="button"
      :disabled="activePage >= pagingArray()"
      @click="pagingNext"
      ><span aria-hidden="true">›</span></CPaginationItem
    >
    <CPaginationItem
      aria-label="Next"
      component="button"
      :disabled="activePage >= pagingArray()"
      @click="pagingLast"
      ><span aria-hidden="true">»</span></CPaginationItem
    >
  </CPagination>
</template>
<!-- <CIcon :icon="'cilChevronDoubleLeft'" />
<CIcon :icon="'cilChevronLeft'" />
<CIcon :icon="'cilChevronRight'" />
<CIcon :icon="'cilChevronDoubleRight'" /> -->
<script lang="js">

export default {
  components: {  },
  name: "CustomPaging",
  props: {
     pages: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 20,
    },
    activePage: {
      default: 1,
    },
  },
  emits:['pageClick'],
  mounted() {
  },
  watch: {

  },
  data() {
    return {
        maxPagingNum:5,
        pagingNumFirst:0,
        pagingNumLast:0,
    };
  },
  created(){
    this.renderInit();
  },
  methods: {
        renderInit(activePage){
            activePage = activePage == undefined ? this.activePage : activePage
            var totalPage = Math.ceil(this.pages / this.limit);
            var pageGroup = Math.ceil(activePage / this.maxPagingNum);

            var last = pageGroup * this.maxPagingNum;
            if (last > totalPage) last = totalPage;
            var first = last - (this.maxPagingNum - 1) <= 0 ? 1 : last - (this.maxPagingNum - 1);
            // var next = last + 1;
            // var prev = first - 1;

            this.pagingNumFirst = first;
            this.pagingNumLast = last;
        },
        pageClick(item){

            this.$emit("pageClick",item);this.renderInit(item)
        },
        pagingFirst(){

            this.$emit("pageClick",1);this.renderInit(1)
        },
        pagingPrev(){
            var result = this.activePage
            if(result > 1){
                result -= 1;
            }
            this.$emit("pageClick",result);
            this.renderInit(result)
        },
        pagingNext(){
             var result = this.activePage
              if(result < this.pagingArray()){
                result += 1;
            }
            this.$emit("pageClick",result);this.renderInit(result)
        },
        pagingLast(){
            var result = this.pagingArray()
            this.$emit("pageClick",result);
            this.renderInit(result)
        },
        pagingArray(){
            var num = this.pages / this.limit;
            num= Number(num)
            num = Math.floor(num);
            if((this.pages % this.limit)  > 0){
               num +=1;
            }
            return num;
        },

    },

};
</script>
<style scoped>
/*    form Style
----------------------------------------------------- */
@media only screen and (min-width: 320px) {
}
</style>
