<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="require('@/assets/images/yelo_02.png')" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Logout
      </CDropdownHeader>
      <button type="button" class="dropdown-item" @click="logoutClick">
        <CIcon icon="cil-lock-locked" /> 로그아웃
      </button>
      <!-- <CDropdownItem type="button">
        <CIcon icon="cil-lock-locked" /> 로그아웃
      </CDropdownItem> -->
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import Session from '../modules/Session'

export default {
  methods: {
    data: function () {
      return {}
    },
    // 로그아웃
    logoutClick() {
      Session.clear()
      // this.$router.
      location.reload(true)
    },
  },
}
</script>
