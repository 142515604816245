<template>
  <router-view v-if="routeLoading === true" />
  <div v-if="isLoading" class="isLoading">
    <CSpinner color="warning" />
  </div>
</template>

<script>
import Root from './views/root'
export default {
  name: 'App',
  extends: Root,
  components: {},
  data: function () {
    return {
      routeLoading: false,
    }
  },
  computed: {},
  async created() {
    this.routeLoading = true
  },
  methods: {},
}
</script>

<style scoped>
.isLoading {
  position: fixed;
  inset: 0px;
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
}
</style>

<style lang="scss">
@import 'styles/style';
</style>
