<template>
  <component
    :is="item"
    v-for="(item, i) of $options.components"
    :key="'ic_' + name + i"
    :name="name"
    :color="color"
    :strokeWidth="strokeWidth"
    :sizeType="sizeType"
    :customStyle="customStyle"
    :customClass="customClass"
    :secondColor="secondColor"
  />
</template>
<script lang="js">
import bi from './bi'
import sygnet from './sygnet'

export default {
  name: "svgIcon",
  components: {
    bi,sygnet
  },
  props: ['name','color','strokeWidth','sizeType','customStyle','secondColor','customClass'],
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},

};
</script>
<style scoped>
@media only screen and (min-width: 320px) {
}
</style>
