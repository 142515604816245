import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Session from './modules/Session'
import CoreuiVue from '@coreui/vue'
import { CIcon } from '@coreui/icons-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Toast, { useToast } from 'vue-toastification'

import 'vue-toastification/dist/index.css'
import $ from 'jquery'
import './assets/css/common.css'

import popupAlert from '@/components/modal/popupAlert'

import { iconsSet as icons } from '@/assets/icons'
import svgIcon from '@/components/svg'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(CoreuiVue)
app.config.globalProperties.$JQ = $
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.config.globalProperties.$toast = useToast()
app.config.globalProperties.$session = Session
app.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 10,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      return false
    }
    return toast
  },
  position: 'top-center',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: false,
  icon: 'ic_toast',
  rtl: false,
  // this.$toast.success("등록에 성공했습니다 마음이 득근득근!");
  // this.$toast.error("등록에 실패하였습니다 다시 시도해주세요");
  // this.$toast("정보가 등록되었습니다");
})

app.component('popupAlert', popupAlert)
app.component('svgIcon', svgIcon)

app.mount('#app')
