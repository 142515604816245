'use strict'

import Axios from 'axios'
import Session from './Session'

/**
 * 오류 코드
 */
const ErrorCode = {}

const RestUrl = {
  BASE_URL: process.env.VUE_APP_BASE_API,
  //   UPLOAD_URL: process.env.VUE_APP_BASE_API,
}

const getConfig = () => {
  const config = { headers: { 'Content-Type': 'application/json' } }
  const accessToken = Session.getAccessToken()
  //debugger;
  if (accessToken) {
    config.headers['authorization'] = accessToken
  }
  return config
}

const Rest = {
  PAGE_LIMIT: 20,

  async get(url) {
    let response
    try {
      response = await Axios.get(url, getConfig())
      if (response.headers.authorization) {
        Session.storeAccessToken(response.headers.authorization)
      }
    } catch (e) {
      return e.response
    }

    return response
  },

  async post(url, body) {
    let response
    try {
      const config = getConfig()
      response = await Axios.post(url, body, config)
      if (response.headers.authorization) {
        Session.storeAccessToken(response.headers.authorization)
      }
    } catch (e) {
      //debugger;
      console.log(e, ' error')
      return e.response
    }

    return response
  },

  async put(url, body, ct = null) {
    let response
    try {
      const config = getConfig()
      if (ct != null) {
        config.headers['content-type'] = ct
      }
      response = await Axios.put(url, body, config)
      if (response.headers.authorization) {
        Session.storeAccessToken(response.headers.authorization)
      }
    } catch (e) {
      //debugger;
      console.log(e, ' error')
      return e.response
    }
    return response
  },
  async delete(url) {
    let response
    try {
      response = await Axios.delete(url, getConfig())
      if (response.headers.authorization) {
        Session.storeAccessToken(response.headers.authorization)
      }
    } catch (e) {
      return e.response
    }

    return response
  },

  async upload(url, body) {
    let response
    try {
      response = await Axios.post(url, body, {
        headers: { 'content-type': 'multipart/form-data' },
      })
    } catch (e) {
      //debugger;
      console.log(e, ' error')
      return e.response
    }
    return response
  },
  uploadBase64(url, body) {
    const config = getConfig()
    config.headers['content-Type'] = 'application/x-www.form-urlencoded'
    return Axios.post(url, body, config)
  },
}

export { RestUrl, Rest, ErrorCode, getConfig }
