<script>
import { Rest, RestUrl } from '../modules/Rest'
import { Common } from '../modules/Common'
import Session from '../modules/Session'
import customPaging from '@/components/libs/customPaging'
export default {
    components: { customPaging },
    data() {
        return {
            root: this,
            common: Common,
            rest: Rest,
            restUrl: RestUrl,
            limit: Rest.PAGE_LIMIT,
            //   _formatter: Formatter,
            _session: Session,
        }
    },
    computed: {
        isLoading() {
            return this.$store.state.isLoading
        },
    },
    async created() {},
    methods: {
        setIsLoading(bool) {
            this.$store.commit('setIsLoading', bool)
        },
      
    },
}
</script>
