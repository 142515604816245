<template>
  <CModal
    backdrop="static"
    :size="'sm'"
    alignment="center"
    :visible="isVisible"
  >
    <CModalHeader dismiss @click="onClose">
      <CModalTitle>알림</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <slot></slot>
    </CModalBody>
    <CModalFooter>
      <CButton color="dark" lass="p-4 pt-2 pb-2" @click="onClose">
        취소
      </CButton>
      <CButton color="warning" lass="p-4 pt-2 pb-2" @click="onPress"
        >확인</CButton
      >
    </CModalFooter>
  </CModal>
</template>

<script>
export default {
  name: 'popup_alert',
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {},
  data() {
    return {
      root: this,
    }
  },
  methods: {
    onPress() {
      this.$emit('onPress')
    },
    onClose() {
      this.$emit('onClose')
    },
  },
}
</script>
<style scoped>
@media only screen and (min-width: 320px) {
}
</style>
