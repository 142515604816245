import {
  createRouter,
  createWebHistory,
} from 'vue-router'
import Session from '../modules/Session'
import DefaultLayout from '@/layouts/DefaultLayout'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/users',
    children: [
      {
        path: '/users',
        name: '회원 관리',
        component: () => import('@/views/users/Users.vue'),
      },
      {
        path: '/users/detail',
        name: '회원 상세',
        component: () => import('@/views/users/UsersDetail.vue'),
      },
      {
        path: '/users/transactions',
        name: '입출금 내역 조회',
        component: () => import('@/views/users/UsersTransactions.vue'),
      },
        {
          path: '/lockup',
          name: 'Lock Up',
          component: () => import('@/views/wallets/Lockup.vue'),
        },
      {
        path: '/wallets',
        name: '마스터 지갑',
        component: () => import('@/views/wallets/Wallets.vue'),
      },
      {
        path: '/wallets/detail',
        name: '지갑 상세',
        component: () => import('@/views/wallets/WalletsDetail.vue'),
      },
      {
        path: '/ethBlocks',
        name: 'Eth Blocks',
        component: () => import('@/views/wallets/EthBlocks.vue'),
      },
      {
        path: '/gatherings',
        name: '게더링 내역',
        component: () => import('@/views/systems/Gatherings.vue'),
      },
      {
        path: '/supplyFees',
        name: '수수료 지급 내역',
        component: () => import('@/views/systems/SupplyFees.vue'),
      },
      {
        path: '/transactionHistory',
        name: '입출금 내역',
        component: () => import('@/views/systems/TransactionHistory.vue'),
      },
      {
        path: '/withdrawalApproval',
        name: '출금 승인',
        component: () => import('@/views/systems/WithdrawalApproval.vue'),
      },

      {
        path: '/versions',
        name: 'APP버전 관리',
        component: () => import('@/views/manage/Versions.vue'),
      },
      {
        path: '/notices',
        name: '공지사항',
        component: () => import('@/views/manage/Notices.vue'),
      },
      {
        path: '/notices/detail',
        name: '공지사항 상세',
        component: () => import('@/views/manage/NoticesDetail.vue'),
      },
      {
        path: '/notices/add',
        name: '공지사항 등록',
        component: () => import('@/views/manage/NoticesAdd.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/pages/Page404'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (Session.hasSession()) {
    next()
  } else {
    if (to.path === '/login') {
      next()
    } else {
      next({ name: 'Login' })
    }
  }
})

export default router
